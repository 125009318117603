import React from "react";

const frontendSkills = [
  {
    subject: "HTML",
    experience: "Experienced",
  },
  {
    subject: "Web3",
    experience: "Intermediate",
  },
  {
    subject: "CSS",
    experience: "Experienced",
  },
  {
    subject: "SASS",
    experience: "Intermediate",
  },
  {
    subject: "JavaScript",
    experience: "Experienced",
  },
  {
    subject: "TypeScript",
    experience: "Experienced",
  },
  {
    subject: "Angular",
    experience: "Experienced",
  },
  {
    subject: "React",
    experience: "Intermediate",
  },
  {
    subject: "React Hook",
    experience: "Intermediate",
  },
  {
    subject: "Redux",
    experience: "Intermediate",
  },
  {
    subject: "RxJs",
    experience: "Intermediate",
  },
  {
    subject: "Tailwind",
    experience: "Basic",
  },
  {
    subject: "Bootstrap",
    experience: "Experienced",
  },
  {
    subject: "Ant Design",
    experience: "Experienced",
  },
  {
    subject: "Material.io",
    experience: "Intermediate",
  },
];

const backendSkills = [
  {
    subject: "Node JS",
    experience: "Intermediate",
  },
  {
    subject: "JAVA Spring Boot",
    experience: "Basic",
  },
  {
    subject: "Solidity",
    experience: "Intermediate",
  },
  {
    subject: "Python",
    experience: "Basic",
  },
  {
    subject: "MySQL",
    experience: "Basic",
  },
  {
    subject: "MongoDB Atlas",
    experience: "Intermediate",
  },
  {
    subject: "Firebase",
    experience: "Basic",
  },
  {
    subject: "AWS Cloud (Serverless)",
    experience: "Basic",
  },
  {
    subject: "Alibaba Cloud",
    experience: "Basic",
  },
  {
    subject: "Azure DevOps",
    experience: "Intermediate",
  },
];

function Skills() {
  return (
    <section className="mt-32 mb-32" id="skills">
      <p className="bold">What Skill I Have</p>
      <h4>My Experience</h4>
      <div className="grid-container-2 mt-32">
        <div className="grid-item ptb-32 plr-48">
          <h2 className="mb-32">Frontend Development</h2>
          <div className="grid-container-2--fixed">
            {frontendSkills.map((skill) => (
              <div className="tl grid-skills-container-2" key={skill.subject}>
                <div>
                  <i className="bi bi-patch-check-fill bi-small"></i>
                </div>
                <div>
                  <p className="m-0 bold">{skill.subject}</p>
                  <p className="m-0 hint">{skill.experience}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="grid-item ptb-32 plr-48">
          <h2 className="mb-32">Backend Development/ DevOps</h2>
          <div className="grid-container-2--fixed">
            {backendSkills.map((skill) => (
              <div className="tl grid-skills-container-2" key={skill.subject}>
                <div>
                  <i className="bi bi-patch-check-fill bi-small"></i>
                </div>
                <div>
                  <p className="m-0 bold">{skill.subject}</p>
                  <p className="m-0 hint">{skill.experience}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
