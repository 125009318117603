import React from "react";

function Contact() {
  return (
    <aside className="mt-32" id="contact">
      <p className="bold">Get in Touch</p>
      <h4>Contact Me</h4>
      <div className="grid-container-3 mt-32">
        <a href="mailto:benhhooxx@gmail.com">
          <div className="grid-item">
            <i className="bi bi-envelope"></i>
            <h2 className="m-0 mt-8">Email</h2>
            <p className="m-0 mb-8">benhhooxx@gmail.com</p>
            <label>Send a message</label>
          </div>
        </a>
        <a href="http://m.me/benhopakcheungben" target="_blank">
          <div className="grid-item">
            <i className="bi bi-messenger"></i>
            <h2 className="m-0 mt-8">Messenger</h2>
            <p className="m-0 mb-8">Pakcheung Ho</p>
            <label>Send a message</label>
          </div>
        </a>
        <a href="https://api.whatsapp.com/send?phone=+85253205324">
          <div className="grid-item">
            <i className="bi bi-whatsapp"></i>
            <h2 className="m-0 mt-8">Whatsapp</h2>
            <p className="m-0 mb-8">+852 53205324</p>
            <label>Send a message</label>
          </div>
        </a>
      </div>
    </aside>
  );
}

export default Contact;
