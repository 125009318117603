// Credit: Antoine Caron
// https://slashgear.github.io/creating-an-image-lazy-loading-component-with-react/
// https://codesandbox.io/s/34vpxnno9p?from-embed=&file=/src/index.js:326-330

import React, { useState, useEffect } from 'react'

const placeHolder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII='

export const LazyImage = ({ src, alt, className, overlayContent, hint, url, topic }) => {
  const [imageSrc, setImageSrc] = useState(placeHolder)
  const [imageRef, setImageRef] = useState()

  const onLoad = event => {
    event.target.classList.add('loaded')
  }

  const onError = event => {
    event.target.classList.add('has-error')
  }

  useEffect(() => {
    let observer
    let didCancel = false

    if (imageRef && imageSrc !== src) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (
                !didCancel &&
                (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImageSrc(src)
                observer.unobserve(imageRef)
              }
            })
          },
          {
            threshold: 0.01,
            rootMargin: '75%',
          }
        )
        observer.observe(imageRef)
      } else {
        // Old browsers fallback
        setImageSrc(src)
      }
    }
    return () => {
      didCancel = true
      // on component cleanup, we remove the listner
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef)
      }
    }
  }, [src, imageSrc, imageRef])

  if (overlayContent !== undefined) {
    return (
      <div className="img-overlay-container">
        <img
          ref={setImageRef}
          src={imageSrc}
          alt={alt}
          onLoad={onLoad}
          onError={onError}
          className={className}
        />
        {topic ? <div className="img-topic-overlay"><div className="img-topic-overlay-text">{topic}</div></div> : ''}
        {hint ? <div className="img-hint-overlay"><a href={url} className="img-hint-overlay-text" target="_blank" rel="noreferrer">{hint}</a></div> : ''}
        <div className="img-overlay-layout-2">
          <div className="img-overlay">
            <div className="img-overlay-text p" dangerouslySetInnerHTML={{ __html: `${overlayContent}` }} />
          </div>
        </div>
      </div>
    ); 
  }

  return ( 
    <img
      ref={setImageRef}
      src={imageSrc}
      alt={alt}
      onLoad={onLoad}
      onError={onError}
      className={className}
    />
  );
}