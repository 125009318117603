import React from "react";

import { LazyImage } from "../helpers";

import Accenture from "../assets/clients-accenture.png";
import Afterschool from "../assets/clients-afterschool.png";
import AXA from "../assets/clients-axa.png";
import Bybit from "../assets/clients-bybit.png";
import HKAA from "../assets/clients-hkaa.png";
import Meatee from "../assets/clients-meatee.png";

function clients() {
  return (
    <section className="mt-32 mb-32" id="clients">
      <p className="bold">What Industry Knowledge I Have</p>
      <h4>Worked / Served Industry</h4>
      <div className="grid-container-6 mt-32">
        <div className="clients">
          <LazyImage
            key={"bybit"}
            src={Bybit}
            alt={"Bybit"}
            className="clients"
          />
        </div>
        <div className="clients">
          <LazyImage
            key={"accenture"}
            src={Accenture}
            alt={"Accenture"}
            className="clients"
          />
        </div>
        <div className="clients">
          <LazyImage key={"hkaa"} src={HKAA} alt={"HKAA"} className="clients" />
        </div>
        <div className="clients">
          <LazyImage key={"axx"} src={AXA} alt={"AXA"} className="clients" />
        </div>
        <div className="clients">
          <LazyImage
            key={"meatee"}
            src={Meatee}
            alt={"Meatee"}
            className="clients"
          />
        </div>
        <div className="clients">
          <LazyImage
            key={"afterschool"}
            src={Afterschool}
            alt={"Afterschool"}
            className="clients"
          />
        </div>
      </div>
    </section>
  );
}

export default clients;
