import React from "react";

import { LazyImage } from "../helpers";

import BenHo from "../assets/BenHo1.png";
import Resume from "../assets/resume/resume_19_12_2022.pdf";

export default function Header() {
  return (
    <header>
      <p className="bold">Hello. I'm</p>
      <h1 className="quotation">
        HO Pak Cheung Ben
        <span className="ml-8">
          <i className="bi bi-animation bi-heart-fill"></i>
        </span>
      </h1>
      <p>Frontend Development Engineer</p>
      <div className="header-button">
        <a href={Resume} download>
          <button className="primary ripple mb-16">Download CV</button>
        </a>
        {/* <button className="primary ripple mb-16 ml-8">Contact Me</button> */}
      </div>
      <div className="grid-container grid-container-3--fixed">
        <div className="grid-container-1 grid-header-lhs-container header-icon-set mb-32">
          <a
            href="https://www.linkedin.com/in/pak-cheung-ho-303baa175/"
            target="_blank"
            rel="noreferrer"
            className=""
          >
            <i className="bi bi-linkedin"></i>
          </a>
          <a
            href="https://github.com/benhhooxx"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bi bi-github"></i>
          </a>
        </div>
        <div className="propic-parent mt-32 mb-32">
          <LazyImage
            key={1}
            src={BenHo}
            alt={"Ben Ho"}
            className="propic-child"
          />
        </div>
        <div className="grid-container-1 grid-header-rhs-container mb-32">
          <p className="header-scroll-down m-0 mb-32">
            Scroll down{" "}
            <span>
              <i className="bi bi-arrow-right"></i>
            </span>
          </p>
        </div>
      </div>
      <div className="border-radius-item"></div>
      <div className="border-radius-item-2"></div>
      <div className="border-radius-item-3"></div>
      <div className="border-radius-item-4"></div>
    </header>
  );
}
