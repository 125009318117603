import React from 'react';
import './css';
import { Header, Showcases, Contact, Footer, About, Skills, Clients } from './components';

const App = () => { 
  return (
    <div className="main-container">
      <Header />
      <About />
      <Skills />
      <Showcases />
      <Clients />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;