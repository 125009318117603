import React from "react";

import BenHo from "../assets/BenHo2.png";

import { LazyImage } from "../helpers";

function About() {
  return (
    <section className="mt-32 mb-32" id="about">
      <p className="bold">Get To Know</p>
      <h4>About Me</h4>
      <div className="grid-about-me-container mt-32">
        <div className="grid-about-me-lhs-container">
          <div className="about-me-img">
            <LazyImage
              key={1}
              src={BenHo}
              alt={"Ben Ho"}
              className="full-percentage"
            />
          </div>
        </div>
        <div className="grid-container-1">
          <div className="grid-container-3">
            <a href="#contact">
              <div className="grid-item">
                <i className="bi bi-award"></i>
                <h2 className="mt-16">Experience</h2>
                <p className="m-0">2+ Years Working</p>
                <label>More details</label>
              </div>
            </a>
            <a href="#skills">
              <div className="grid-item">
                <i className="bi bi-people"></i>
                <h2 className="mt-16">Specialization</h2>
                <p className="m-0">15+ FE/ BE Skill-sets</p>
                <label>More details</label>
              </div>
            </a>
            <a href="#showcases">
              <div className="grid-item">
                <i className="bi bi-window"></i>
                <h2 className="mt-16">Past Projects</h2>
                <p className="m-0">Serviced 10+ Clients</p>
                <label>More details</label>
              </div>
            </a>
          </div>
          <div className="tl">
            <p>
              I'm currently the Frontend Development Engineer in Bybit, which
              provides a crypto currency trading platform, and I am the one of
              project initiator of Bybit Visa Card.
            </p>
            <p>
              I joined total three frontend developments, for instance the Hong
              Kong airport logistics data platform with Hong Kong Airport
              Authority (HKAA) and the projects of startup companies
              (AfterSchool, Meatee). In these projects, I was working under the
              Agile working style, that's why I also took the PSM I
              certification to get more idea on Agile. Apart form the project
              management, I also have the UI/UX design experience when I am
              running a startup company to the frontend and mobile development
              UI/UX design, and working with UX designer when I am frontend
              developer.
            </p>
            <p>
              I'm the key Technology Consultant trainee in Hong Kong Accenture
              Technology. I noly only handled the worldwide insurance client -
              AXA HK to conduct the Journey-to-cloud (J2C) project, but also
              assist HKAA to replicate the logistics platform to China Bonded
              Logistics Park (BLP).
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
