import React from "react";

import { LazyImage } from "../helpers";

import Showcase1 from "../assets/showcase1.png";
import Showcase2 from "../assets/showcase2.png";
import Showcase3 from "../assets/showcase3.png";
import Showcase4 from "../assets/showcase4.png";
import Showcase5 from "../assets/showcase5.png";
import Showcase6 from "../assets/showcase6.png";
import Showcase7 from "../assets/showcase7.png";
import Showcase8 from "../assets/showcase8.png";
import Showcase9 from "../assets/showcase9.png";

export default function Showcases() {
  return (
    <section className="mt-32 mb-32" id="showcases">
      <p className="bold">My Recent Work</p>
      <h4>Portfolio</h4>
      <div className="grid-container-2 mt-32">
        <LazyImage
          key={8}
          src={Showcase8}
          alt={"Bybit"}
          className="pointer"
          overlayContent={`<h1>Bybit Fiat Low-code Solution & Bybit Visa Card</h1>`}
          topic="Bybit Fiat Low-code Solution & Bybit Visa Card"
        />
        <LazyImage
          key={5}
          src={Showcase5}
          alt={"HKIA STFS Platform"}
          overlayContent={`
            <h1>HKIA STFS Platform</h1><br/>
            HKIA STFS Platform is a sea logistics platform to provide high transparency and comprehensive workflow to Hong Kong Customs and Excise Department (C&ED), operator, and monitored by HKIA & Hong Kong C&ED. <br/><br/>
            I'm the frontend technical lead to lead the frontend team to complete the project and make it go-live. Also, I need to conduct the technical design, for instance, the project is used Angular 10 and Nx to develop, it's applied the monorepo concept to deliver multiple applications in this repository. Apart form the architecture design, I need to review my teammate pull request by AzureDevOps as well.
            `}
          hint={"Live Demo"}
          url={"https://www.youtube.com/watch?v=oeCIjrWJh0I"}
          topic="HKIA STFS Platform"
        />
        <LazyImage
          key={1}
          src={Showcase1}
          alt={"HKIA Cargo Data Platform"}
          overlayContent={`
            <h1>HKIA Cargo Data Platform</h1><br/>
            HKIA Cargo Data Platform is an air logistics platform to provide high transparency and comprehensive workflow to Regulated Agent (RA), Registration as Regulated Air Cargo Screening Facility (RACSF), Trucker, Cargo Terminal Operator (CTO), and monitored by HKIA & CAD. <br/><br/>
            I'm the frontend developer to assist the frontend team to complete the project and make it go-live within 8 months timeline. Apart form the development work, I also need to communicate with the Business Analyst, UI/UX Designer, Quality Assurance Engineer, and report my status to my team lead to ensure the deliverable keep in check.
            `}
          hint={"Live Demo"}
          url={"https://portal.hkiacargo.com/auth/login"}
          topic="HKIA Cargo Data Platform"
        />
        <LazyImage
          key={6}
          src={Showcase6}
          alt={"AXA"}
          overlayContent={`<h1>AXA Journey to Cloud (J2C)</h1>`}
        />
        <LazyImage
          key={7}
          src={Showcase7}
          alt={"HKIA Dongguan Module"}
          overlayContent={`<h1>HKIA Dongguan Module</h1>`}
          topic="AXA Journey to Cloud (J2C)"
        />
        <LazyImage
          key={2}
          src={Showcase2}
          alt={"ACRA"}
          overlayContent={`
          <h1>ACRA</h1><br/>
          ACRA is Accenture AWS Business Group (AABG) project to deliver a PoC to Accenture global leader to review the application degree of the blockchain technology used with AWS. <br/><br/>
          I'm the frontend developer in this mini-project. This project is used the pure HTML, CSS and JavaScript to develop since it has a very rush time limit. However, I also need to work with the UI designer to beauty the application to visualize the diagram of the blockchain data connectivity.`}
          topic="ACRA - Accenture AWS Business Group (AABG)"
        />
        <LazyImage
          key={3}
          src={Showcase3}
          alt={"Meatee"}
          overlayContent={`<h1>Meatee</h1><br/>
          Developed a social media platform for food review sharing. I have used ReactJS and Redux for website frontend development in this project, and used Node.js for backend development. Furthermore, including cloud deployment, SEO enhancement, data analyst with google analytics.`}
          topic="Meatee Ltd. (F&B)"
        />
        <LazyImage
          key={4}
          src={Showcase4}
          alt={"Afterschool"}
          overlayContent={`
            <h1>Afterschool</h1><br/>
            Developed as a tutorial platform for the HKDSE students, the project involves the element of video streaming and course recommendations.
            `}
          hint={"Live Demo"}
          url={"https://afterschool.com.hk"}
          topic="Afterschool Ltd. (Education)"
        />
        <LazyImage
          key={9}
          src={Showcase9}
          alt={"Imperium Empires Tech Quick"}
          overlayContent={`<h1>Imperium Empires</h1><br/>
          This project was developed by ReactJS. I used React Spring to handle the animation.
          `}
          topic="Imperium Empires Technical Quiz (Animation)"
        />
      </div>
    </section>
  );
}
